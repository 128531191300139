import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
// import CodeEffentiRoll from '../components/CodeEffentiRoll'


export const ServiceConseilTemplate = ({ title, content, contentComponent }) => {
    const PageContent = contentComponent || Content

    return (
        <div className="wrapper service-bg">
            <div className="container">
                <section className="main-ctn service-conseil">
                    {/*<PageContent className="content" content={content} />*/}
                    <div className="content">
                        <div className="columns">
                            <div className="column is-8">
                                <h1 className="title">
                                    {/*{title}*/}
                                    Le service-conseil <br/>c'est quoi
                                </h1>
                                Nos experts SYMPATIQUES chez vous ! Chez Effenti, nous avons uniquement des experts TI sympathiques !
                                Ils sont compétents, dévoués et apportent une vision unique face aux problèmes que rencontre nos clients.
                                Voici la liste des experts prêts à vous aider:
                                <ul className="servicesEmplois">
                                    <li>Développeurs JAVA</li>
                                    <li>Développeurs .NET</li>
                                    <li>Architectes organiques</li>
                                    <li>Analystes fonctionnels</li>
                                    <li>Architectes fonctionnels</li>
                                    <li>QA</li>
                                    <li>Chargés de projets</li>
                                </ul>
                            </div>
                            <div className="column is-4">
                            </div>
                        </div>


                    </div>


                </section>
            </div>
            <div className="spacer-red triangle-red-bottom is-relative"></div>
        </div>
    )
}

ServiceConseilTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const ServiceConseil = ({ data }) => {
    const { markdownRemark: post } = data
    return (
        <Layout>
            <ServiceConseilTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
            />
        </Layout>
    )
}

ServiceConseil.propTypes = {
    data: PropTypes.object.isRequired,
}

export default ServiceConseil

export const serviceConseilQuery = graphql`
  query ServiceConseil($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
